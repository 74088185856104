import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/fede/source/largoplacismo/src/components/layout.js";
import { Link } from "gatsby";
import Seo from "../components/seo";
import LinkButton from "../components/link-button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Seo title="¿Qué es el
largoplacismo?" mdxType="Seo" />
    <div className='highlight'> El largoplacismo sostiene que deberíamos reflexionar mucho más sobre cómo podrían afectar nuestras acciones a las generaciones futuras. </div>
    <p>{`Esta postura ética se apoya en tres ideas. La primera es que las personas que vivan en el futuro importan. No hay duda de que nuestras vidas importan tanto como las de las personas que vivieron hace miles de años, así que ¿por qué no deberían importar en la misma medida las vidas de quienes vivan dentro de miles de años? La segunda es que el futuro puede ser inmenso. Si no se produce una catástrofe, la mayoría de las personas que vivirán a lo largo de la historia aún no han nacido. La tercera es que nuestras acciones pueden influir previsiblemente en el devenir del futuro a largo plazo. En resumen, puede que sea nuestra responsabilidad asegurarnos de que las generaciones futuras sobrevivan y prosperen.`}</p>
    <p>{`El `}<a parentName="p" {...{
        "href": "https://www.bbc.com/future/article/20220805-what-is-longtermism-and-why-does-it-matter"
      }}>{`largoplacismo`}</a>{` no es una perspectiva única y definida hasta el más mínimo detalle. Se trata más bien de una familia de perspectivas unidas por un principio común: la importancia de salvaguardar y mejorar el futuro a largo plazo de la humanidad. Dado que aún queda mucho por aprender acerca de cómo alcanzar esta meta, el largoplacismo también se considera una especie de paradigma para las investigaciones que busquen mejorar el mundo en campos como la filosofía, la economía, el derecho e, incluso, la inteligencia artificial (IA).`}</p>
    <p>{`La preocupación por el futuro a largo plazo no es una idea nueva. Sin embargo, hoy en día hay un creciente número de investigadores, personas comprometidas, emprendedores y legisladores que deciden guiarse por esta perspectiva y que se toman en serio sus implicaciones.`}</p>
    <p>{`Este sitio web busca ser un recurso útil para aprender sobre los diferentes aspectos del largoplacismo. Si quieres leer una introducción algo más extensa sobre las ideas principales, selecciona el enlace de abajo. El tiempo de lectura es de unos 10 minutos.`}</p>
    <LinkButton to='/introduccion' mdxType="LinkButton">Sigue leyendo: <b>introducción al largoplacismo</b></LinkButton>
    <p>{`En la página `}<Link to='/preguntas-frecuentes' mdxType="Link">{`Preguntas frecuentes`}</Link>{` se intenta dar respuesta a las cuestiones y objeciones más habituales acerca del largoplacismo. Por ejemplo, ¿por qué motivo mejorar el futuro lejano debería ser una de nuestras máximas prioridades si en la actualidad hay tantos problemas inmediatos?`}</p>
    <LinkButton to='/preguntas-frecuentes' mdxType="LinkButton">Sigue leyendo: <b>a preguntas frecuentes sobre el largoplacismo</b></LinkButton>
    <p>{`En la página `}<Link to='/recursos' mdxType="Link">{`Recursos`}</Link>{` hay una lista de libros, artículos, entrevistas y charlas que puedes
consultar para profundizar en diversos aspectos del pensamiento largoplacista.`}</p>
    <LinkButton to='/recursos' mdxType="LinkButton">Sigue leyendo: <b>recursos para saber más</b></LinkButton>
    <p>{`Por último, si te interesa aportar tu grano de arena, en la página `}<Link to='/involucrate' mdxType="Link">{`Involúcrate`}</Link>{` encontrarás algunas sugerencias al respecto.`}</p>
    <LinkButton to='/involucrate' mdxType="LinkButton">Sigue leyendo: <b>cómo involucrarse</b></LinkButton>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      